/* App.css */

body {
  margin: 0;
  font-family: sans-serif;
  background-color: #070707;
}

#outer-gallery-container {
  width: 100vw;
  overflow-x: hidden;
}

#gallery {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  grid-gap: 5px;
  padding: 10px;
  margin-bottom: 50px;
  grid-auto-flow: dense;
  overflow: hidden;
}

.gallery-item {
  background-color: #303030;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  border: none;
  cursor: pointer;
  transition: all 0.3s ease;
}

.medium {
  grid-column: span 1;
  grid-row: span 1;
  width: 200px;
  height: 200px;
}

.large {
  grid-column: span 2;
  grid-row: span 2;
  width: 410px;
  height: 410px;
}

/* Mobile-specific styles */
@media (max-width: 768px) {
  #gallery {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 5px;
    padding: 5px;
    width: 100%;
    box-sizing: border-box;
  }

  .gallery-item.large {
    grid-column: span 2;
    width: 100%;
    height: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
  }

  #gallery .gallery-item.medium {
    width: 100%;
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
  }

  #gallery .gallery-item.medium img {
    width: 95%;
    height: 95%;
    border-radius: 5px;
    object-fit: cover;
    box-sizing: border-box;
  }
}

.hidden {
  visibility: hidden;
}

.fading-out {
  opacity: 0.5;
}

.gallery-item:hover {
  background-color: #4e0000;
}

.gallery-item img {
  max-width: 90%;
  max-height: 90%;
}

.title {
  position: absolute;
  top: 0;
  left: 0;
  padding: 5px;
  background-color: transparent;
  color: #8d8d8d;
  font-size: 10px;
  font-weight: bold;
  width: 80%;
  text-align: left;
}

/* Media Overlay Styles */
#overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(116, 48, 48, 0.8);
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 1s ease;
}

#overlay.visible {
  opacity: 1;
}

.media-player {
  width: 60%;
  max-width: 300px;
  height: auto;
  max-height: 80vh;
  margin: 0 auto;
  background-color: #1d1d1d;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  position: relative;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.media-player.visible {
  opacity: 1;
}

.media-player .thumbnail {
  width: 100%;
  height: auto;
  margin-bottom: 10px;
}

.media-player .audio-player {
  width: 100%;
  color: #fff;
  outline: none;
  border-radius: 5px;
  padding: 5px;
}

.audio-player::-webkit-media-controls-panel {
  background-color: #494949;
}

.audio-player::-webkit-media-controls-play-button,
.audio-player::-webkit-media-controls-timeline,
.audio-player::-webkit-media-controls-current-time-display,
.audio-player::-webkit-media-controls-time-remaining-display,
.audio-player::-webkit-media-controls-seek-back-button,
.audio-player::-webkit-media-controls-seek-forward-button,
.audio-player::-webkit-media-controls-mute-button,
.audio-player::-webkit-media-controls-volume-slider {
  color: #fff;
}

.button-container {
  display: flex;
  justify-content: space-around;
  width: 100%;
}

button {
  background-color: #ff4081;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
}

button:hover {
  background-color: #e60050;
}

/* Mobile-specific styles */
@media (max-width: 768px) {
  .media-player {
    width: 95%;
    max-width: 100%;
    padding: 10px;
  }

  .media-player .thumbnail {
    width: 100%;
    height: auto;
  }

  .media-player .audio-player {
    width: 100%;
    padding: 10px;
  }
}
